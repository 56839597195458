import { fetchCountryStates } from './fetchCountryStates';

export async function getCountryStateId(address, countryId) {
	let countryStates = await fetchCountryStates(countryId);
	let countryStateId = null;
	if (countryStates &&
		countryStates.data &&
		countryStates.data[0] &&
		((countryStates.data[0].translated && countryStates.data[0].translated.name) || countryStates?.data?.[i]?.shortCode ) &&
		(address.locality || address.administrativeArea)
	) {
		for (let i=0; i< countryStates?.data?.length; i++) {
			if (
				countryStates?.data?.[i]?.translated?.name === address?.locality
				||
				countryStates?.data?.[i]?.translated?.name === address?.administrativeArea
				||
				(
					countryStates?.data?.[i]?.translated?.name === 'London, City of' && address?.locality === 'London'
				)
				||
				(
					countryStates?.data?.[i]?.shortCode.match(/^[A-Za-z]{2}-(\w{2})$/) &&
					countryStates?.data?.[i]?.shortCode.match(/^[A-Za-z]{2}-(\w{2})$/)[1] === address?.administrativeArea
				)
			) {
				countryStateId = countryStates.data[i].id;
				break;
			}
		}
	}

	return countryStateId;
}